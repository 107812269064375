import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  AlerteFinEntrepriseDiff,
  CentreMesure,
  Contact,
  EnumActivite,
  EnumStructureContractualisationStatus,
  Invitation,
  MembreEquipe,
  Signataire,
  SignataireInterface,
  Structure,
  SyntheseCompletudeSecondNiveau,
  SyntheseControleSecondNiveau,
  Verrouillage,
} from '@shared-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { NonFinancialsInformationsResponse } from '../../../../../libs/shared-ui/src/lib/shared/models/non-financials-informations-response.model';
import { NonFinancialsInfosSaveRequest } from '../../../../../libs/shared-ui/src/lib/shared/models/non-financials-infos-save-request.model';
import { AlerteFinEntrepriseDiffInstructeur } from 'libs/shared-ui/src/lib/shared/models/alerteFinEntrepriseDiffInstructeur.model';

@Injectable()
export class StructureService {
  structureUrl = environment.apiUrl + 'structures';
  notifUrl = environment.apiNotif;
  projetAdminUrl = environment.apiAdminUrl;
  evalApiUrl = environment.apiUrl + 'evaluateurs/';
  apiUrl = environment.apiUrl;

  private structureSubject = new BehaviorSubject(null);
  private structuresListSubject = new BehaviorSubject(null);

  private getApiUrl(): string {
    return this.sharedFunction.isSiteAdmin() ? this.projetAdminUrl : this.evalApiUrl;
  }

  public setStructureObservable(structure: Structure): void {
    this.structureSubject.next(structure);
  }

  public getStructureObservable(): Observable<Structure> {
    return this.structureSubject.asObservable();
  }

  public updateStructureList(allStructures: Structure[], structure: Structure): void {
    const index = allStructures.findIndex(struct => {
      return struct.id === structure.id;
    });
    const newList = allStructures;
    newList[index] = structure;
    this.setStructuresListObservable(newList);
  }

  public setStructuresListObservable(structures: Structure[]): void {
    this.structuresListSubject.next(structures);
  }

  public setUniqueStructureListObservable(structure: Structure, structures: Structure[]): void {
    const index = structures.findIndex(struct => {
      return struct.id === structure.id;
    });
    const newList = structures;
    newList[index] = structure;
    this.setStructuresListObservable(newList);
  }

  public getStructuresListObservable(): Observable<Structure[]> {
    return this.structuresListSubject.asObservable();
  }

  constructor(private httpClient: HttpClient, private sharedFunction: SharedFunction) {}

  getStructures(): Observable<HttpResponse<Structure[]>> {
    return this.httpClient.get<Structure[]>(this.structureUrl, { observe: 'response' });
  }

  getStructuresByIdProject(idProject: string): Observable<HttpResponse<Structure[]>> {
    return this.httpClient.get<Structure[]>(this.structureUrl, {
      params: { idProjet: idProject },
      observe: 'response',
    });
  }

  getStructureById(idStructure: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.get<Structure>(this.structureUrl + '/' + idStructure, { observe: 'response' });
  }

  addStructure(structure: Structure): Observable<HttpResponse<Structure>> {
    if (structure.siret) {
      structure.siret = structure.siret.replace(/\s/g, '');
    }
    return this.httpClient.post<Structure>(this.structureUrl, JSON.stringify(structure), { observe: 'response' });
  }

  updateStructure(structure: Structure): Observable<HttpResponse<Structure>> {
    if (structure.siret) {
      structure.siret = structure.siret.replace(/\s/g, '');
    }
    if (structure.lieuRD?.siret) {
      structure.lieuRD.siret = structure.lieuRD.siret.replace(/\s/g, '');
    }
    return this.httpClient.put<Structure>(this.structureUrl, JSON.stringify(structure), { observe: 'response' });
  }

  updateMembreEquipeStructure(structureId: string, membreEquipe: MembreEquipe): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.structureUrl + '/' + structureId + '/equipe', JSON.stringify(membreEquipe), {
      observe: 'response',
    });
  }

  deleteStructure(idStructure: string, structure: Structure): Observable<HttpResponse<object>> {
    return this.httpClient.request('delete', this.structureUrl + '/' + idStructure, {
      body: JSON.stringify(structure),
      observe: 'response',
    });
  }

  addContactStructure(idStructure: string, contact: Contact): Observable<HttpResponse<Structure>> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts', JSON.stringify(contact), {
      observe: 'response',
    });
  }

  updateContactStructure(idStructure: string, contact: Contact, idContact: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact, JSON.stringify(contact), {
      observe: 'response',
    });
  }

  deleteContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.delete<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact);
  }

  getInvitationsContactsStructure(idStructure: string): Observable<HttpResponse<Invitation[]>> {
    return this.httpClient.get<Invitation[]>(this.structureUrl + '/' + idStructure + '/contacts/invitations', {
      observe: 'response',
    });
  }

  putStructureVerrouillage(id: string, verrouillage: Verrouillage): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.getApiUrl() + 'structures/' + id + '/verrouillage', JSON.stringify(verrouillage), {
      observe: 'response',
    });
  }

  putStructureEligibilite(id: string, eligibilite: Verrouillage): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(this.getApiUrl() + 'structures/' + id + '/eligibilite', JSON.stringify(eligibilite), {
      observe: 'response',
    });
  }

  patchStructureSyntheseControleSecondNiveau(
    id: string,
    syntheseControle: SyntheseControleSecondNiveau
  ): Observable<HttpResponse<Structure>> {
    return this.httpClient.patch<Structure>(
      this.getApiUrl() + 'structures/' + id + '/synthese-controle-second-niveau',
      JSON.stringify(syntheseControle),
      {
        observe: 'response',
      }
    );
  }

  patchStructureSyntheseSecondNiveauCommentaire(
    id: string,
    syntheseCompletudeSecondNiveau: SyntheseCompletudeSecondNiveau
  ): Observable<HttpResponse<Structure>> {
    return this.httpClient.patch<Structure>(
      this.getApiUrl() + 'structures/' + id + '/commentaire-completude-seconde-niveau',
      JSON.stringify(syntheseCompletudeSecondNiveau),
      {
        observe: 'response',
      }
    );
  }

  putStructureEreputation(id: string, ereputation: string): Observable<HttpResponse<Structure>> {
    const ereput = { statut: ereputation };
    return this.httpClient.put<Structure>(this.getApiUrl() + 'structures/' + id + '/e-reputation', JSON.stringify(ereput), {
      observe: 'response',
    });
  }

  putStructureAlerteFinanciereED(
    id: string,
    AfEd: AlerteFinEntrepriseDiff,
    activite: EnumActivite = EnumActivite.ELIGIBILITE
  ): Observable<HttpResponse<Structure>> {
    const baseUrl = this.getApiUrl() + 'structures/' + id;
    const activitePath = activite === EnumActivite.ELIGIBILITE ? '/eligibilite' : '/controle-second-niveau';
    const url = baseUrl + activitePath + '/alerte-financiere-ed';
    return this.httpClient.put<Structure>(url, JSON.stringify(AfEd), {
      observe: 'response',
    });
  }
  getStructureAlerteFinanciere(idStructure: string): Observable<HttpResponse<AlerteFinEntrepriseDiffInstructeur>> {
    return this.httpClient.get<AlerteFinEntrepriseDiffInstructeur>(this.apiUrl + 'structures/' + idStructure + '/alerte-financiere-ed', {
      observe: 'response',
    });
  }
  putStructureContrat(id: string, modeleContrat: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(
      this.getApiUrl() + 'structures/' + id + '/contractualisation/modele-contrat',
      {
        modeleContrat: modeleContrat,
        statut: 'VALIDE',
      },
      {
        observe: 'response',
      }
    );
  }

  putStatutDonneesBudgetaire(id: string, statut: string): Observable<HttpResponse<Structure>> {
    return this.httpClient.put<Structure>(
      this.getApiUrl() + 'structures/' + id + '/contractualisation/statut-donnees-budgetaires',
      {
        statutDonneesBudgetaires: statut,
      },
      {
        observe: 'response',
      }
    );
  }

  public putStructureContractualisationStatus(id: string, status: EnumStructureContractualisationStatus): Observable<Structure> {
    return this.httpClient.put<Structure>(this.projetAdminUrl + 'structures/' + id + '/contractualisation/statut-avancement', {
      statut: status,
    });
  }

  getStructureDirectionRegionale(ids: string[], role?: string): Observable<HttpResponse<CentreMesure[]>> {
    let filters = '?ids=' + ids.toString();
    if (role) {
      filters += '&roleStructure=' + role;
    }
    return this.httpClient.get<CentreMesure[]>(this.structureUrl + '/lieu-rd/direction-regionale' + filters, { observe: 'response' });
  }

  getStructuresInfoContacts(projetId: string): Observable<HttpResponse<Structure[]>> {
    return this.httpClient.get<Structure[]>(this.projetAdminUrl + 'structures/infos-contacts', {
      params: { projetId: projetId },
      observe: 'response',
    });
  }

  putStructureDossierCommercial(id: string, numero: string): Observable<Structure> {
    return this.httpClient.post<Structure>(this.projetAdminUrl + 'structures/' + id + '/dossiers-commerciaux', {
      numero: numero,
    });
  }

  deleteStructureDossierCommercial(id: string, numeroDC: string): Observable<Structure> {
    return this.httpClient.delete<Structure>(this.projetAdminUrl + 'structures/' + id + '/dossiers-commerciaux/' + numeroDC);
  }
  disableContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact + '/desactiver', null);
  }

  reinvitationContactStructure(idStructure: string, idContact: string): Observable<Structure> {
    return this.httpClient.post<Structure>(this.structureUrl + '/' + idStructure + '/contacts/' + idContact + '/reactiver', null);
  }
  getNonFinancialInformations(idStructure: string): Observable<NonFinancialsInformationsResponse> {
    return this.httpClient.get<NonFinancialsInformationsResponse>(
      this.getApiUrl() + 'structures/' + idStructure + '/informations-non-financieres'
    );
  }
  saveNonFinancialsInformations(idStructure: string, nonFinancialsInformations: NonFinancialsInfosSaveRequest) {
    return this.httpClient.put<NonFinancialsInformationsResponse>(
      this.getApiUrl() + 'structures/' + idStructure + '/informations-non-financieres',
      nonFinancialsInformations
    );
  }

  saveSignataire(idStructure: string, signataire: SignataireInterface): Observable<HttpResponse<Signataire>> {
    return this.httpClient.patch<SignataireInterface>(
      `${this.getApiUrl()}structures/${idStructure}/contractualisation/signataires`,
      JSON.stringify(signataire),
      {
        observe: 'response',
      }
    );
  }

  getSignataires(idStructure: string): Observable<HttpResponse<Signataire[]>> {
    return this.httpClient.get<SignataireInterface[]>(`${this.getApiUrl()}structures/${idStructure}/contractualisation/signataires`, {
      observe: 'response',
    });
  }
}
